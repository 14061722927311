import React, { FC } from "react";
import { DailyWeatherFormatted } from "../../types";
import dayjs from "dayjs";
import * as locale_ru from 'dayjs/locale/ru';

interface IWeek {
  week: DailyWeatherFormatted[]
}

export const Week: FC<IWeek> = ({week}) => {
  return(
    <section>
      {
        week.map((w, index) => {
          return index < 3 && (
            <div className="flex flex-col  siblings:mt-4 siblings:border-t siblings:pt-4">
              <div className="font-bold text-sm text-gray-600 mb-2">{dayjs(w.date).locale(locale_ru).format('D MMMM')}</div>
              {
                Object.keys(w.daytime).map((h, i) => {
                  const day = w.daytime[h];
                  const dayTime = ['Ночь', 'Утро', 'День', 'Вечер']
                  return(
                    <div className="flex items-center text-gray-700 py-1">
                      <span className="text-sm text-gray-400 basis-1/4">{dayTime[i]}</span>
                      <span className="flex-0">
                        <img className="w-6" src={`https://anw.by/images/weather/${day.ww}.svg`} alt="" />
                      </span>                      
                      <span className="text-sm text-right flex-1">
                        {Math.round(day.TMP_MIN) === 0 ? '' : (Math.round(day.TMP_MIN) > 0 ? '+' : '')}{Math.round(day.TMP_MIN)} .. 
                        {Math.round(day.TMP_MAX) === 0 ? '' : (Math.round(day.TMP_MAX) > 0 ? '+' : '')}{Math.round(day.TMP_MAX)}
                      </span>
                      <span className="text-xs ml-auto flex-1 justify-end flex items-center text-gray-400">
                        {day.WINDSP_MIN === day.WINDSP_MAX ? `${day.WINDSP_MIN} м/с` : `${day.WINDSP_MIN}-${day.WINDSP_MAX} м/с`}
                        <span className="material-symbols-rounded text-xs ml-1 text-gray-400" style={{transform: `rotate(${day.WINDDIR + 180}deg)`}}>navigation</span>
                      </span>
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
    </section>
  );
}