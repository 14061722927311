[
    {
      "country": "BY",
      "id": 532240,
      "name": "Б.Берестовица",
      "lat": 53.193,
      "lng": 24.017,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 26941,
      "name": "Барановичи",
      "lat": 53.132,
      "lng": 25.972,
      "timezone": 3,
      "icao": "Ba"
    },
    {
      "country": "BY",
      "id": 525252,
      "name": "Белоозерск",
      "lat": 52.468,
      "lng": 25.182,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 540297,
      "name": "Белыничи",
      "lat": 53.998,
      "lng": 29.71,
      "timezone": 3,
      "icao": "Ben"
    },
    {
      "country": "BY",
      "id": 26959,
      "name": "Березин.Зап.",
      "lat": 54.73,
      "lng": 28.35,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 26853,
      "name": "Березино",
      "lat": 53.828,
      "lng": 28.998,
      "timezone": 3,
      "icao": "Be"
    },
    {
      "country": "BY",
      "id": 525250,
      "name": "Берёза",
      "lat": 52.534,
      "lng": 24.981,
      "timezone": 3,
      "icao": "Brz"
    },
    {
      "country": "BY",
      "id": 550295,
      "name": "Бешенковичи",
      "lat": 55.045,
      "lng": 29.458,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 26961,
      "name": "Бобруйск",
      "lat": 53.209,
      "lng": 29.127,
      "timezone": 3,
      "icao": "Bb"
    },
    {
      "country": "BY",
      "id": 26765,
      "name": "Большой Межник",
      "lat": 54.33,
      "lng": 30.267,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 26759,
      "name": "Борисов",
      "lat": 54.266,
      "lng": 28.498,
      "timezone": 3,
      "icao": "Bo"
    },
    {
      "country": "BY",
      "id": 33124,
      "name": "Брагин",
      "lat": 51.797,
      "lng": 30.248,
      "timezone": 3,
      "icao": "Bg"
    },
    {
      "country": "BY",
      "id": 26542,
      "name": "Браслав",
      "lat": 55.642,
      "lng": 27.021,
      "timezone": 3,
      "icao": "Bra"
    },
    {
      "country": "BY",
      "id": 33008,
      "name": "Брест",
      "lat": 52.116,
      "lng": 23.686,
      "timezone": 3,
      "icao": "UMBB"
    },
    {
      "country": "BY",
      "id": 521239,
      "name": "Брест-аэропорт",
      "lat": 52.108,
      "lng": 23.896,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 527306,
      "name": "Буда-Кошелево",
      "lat": 52.716,
      "lng": 30.568,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 535302,
      "name": "Быхов",
      "lat": 53.518,
      "lng": 30.245,
      "timezone": 3,
      "icao": "Byh"
    },
    {
      "country": "BY",
      "id": 33038,
      "name": "Василевичи",
      "lat": 52.252,
      "lng": 29.841,
      "timezone": 3,
      "icao": "Vs"
    },
    {
      "country": "BY",
      "id": 26554,
      "name": "Верхнедвинск",
      "lat": 55.821,
      "lng": 27.94,
      "timezone": 3,
      "icao": "Vd"
    },
    {
      "country": "BY",
      "id": 526312,
      "name": "Ветка",
      "lat": 52.563,
      "lng": 31.173,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 26745,
      "name": "Вилейка",
      "lat": 54.483,
      "lng": 26.9,
      "timezone": 3,
      "icao": "Vk"
    },
    {
      "country": "BY",
      "id": 26666,
      "name": "Витебск",
      "lat": 55.253,
      "lng": 30.249,
      "timezone": 3,
      "icao": "UMII"
    },
    {
      "country": "BY",
      "id": 551304,
      "name": "Витебск-аэропорт",
      "lat": 55.126,
      "lng": 30.35,
      "timezone": 3,
      "icao": "UMII"
    },
    {
      "country": "BY",
      "id": 26923,
      "name": "Волковыск",
      "lat": 53.167,
      "lng": 24.45,
      "timezone": 3,
      "icao": "Vv"
    },
    {
      "country": "BY",
      "id": 26748,
      "name": "Воложин",
      "lat": 54.1,
      "lng": 26.517,
      "timezone": 3,
      "icao": "Vo"
    },
    {
      "country": "BY",
      "id": 542253,
      "name": "Вороново",
      "lat": 54.152,
      "lng": 25.319,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 33001,
      "name": "Высокое",
      "lat": 52.367,
      "lng": 23.383,
      "timezone": 3,
      "icao": "Vy"
    },
    {
      "country": "BY",
      "id": 26947,
      "name": "Ганцевичи",
      "lat": 52.75,
      "lng": 26.45,
      "timezone": 3,
      "icao": "Ga"
    },
    {
      "country": "BY",
      "id": 551277,
      "name": "Глубокое",
      "lat": 55.139,
      "lng": 27.685,
      "timezone": 3,
      "icao": "Glu"
    },
    {
      "country": "BY",
      "id": 529287,
      "name": "Глуск",
      "lat": 52.9,
      "lng": 28.685,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 33041,
      "name": "Гомель",
      "lat": 52.402,
      "lng": 30.963,
      "timezone": 3,
      "icao": "UMGG"
    },
    {
      "country": "BY",
      "id": 525310,
      "name": "Гомель-аэропорт",
      "lat": 52.527,
      "lng": 31.017,
      "timezone": 3,
      "icao": "UMGG"
    },
    {
      "country": "BY",
      "id": 26774,
      "name": "Горки",
      "lat": 54.304,
      "lng": 30.944,
      "timezone": 3,
      "icao": "Gk"
    },
    {
      "country": "BY",
      "id": 555300,
      "name": "Городок",
      "lat": 55.462,
      "lng": 29.988,
      "timezone": 3,
      "icao": "Grd"
    },
    {
      "country": "BY",
      "id": 26820,
      "name": "Гродно",
      "lat": 53.653,
      "lng": 23.842,
      "timezone": 3,
      "icao": "UMMG"
    },
    {
      "country": "BY",
      "id": 536241,
      "name": "Гродно-аэропорт",
      "lat": 53.602,
      "lng": 24.055,
      "timezone": 3,
      "icao": "UMMG"
    },
    {
      "country": "BY",
      "id": 521272,
      "name": "Давид-Городок",
      "lat": 52.055,
      "lng": 27.212,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 537271,
      "name": "Дзержинск",
      "lat": 53.685,
      "lng": 27.133,
      "timezone": 3,
      "icao": "Dzd"
    },
    {
      "country": "BY",
      "id": 524313,
      "name": "Добруш",
      "lat": 52.422,
      "lng": 31.317,
      "timezone": 3,
      "icao": "Dbu"
    },
    {
      "country": "BY",
      "id": 26657,
      "name": "Докшицы",
      "lat": 54.887,
      "lng": 27.761,
      "timezone": 3,
      "icao": "Dk"
    },
    {
      "country": "BY",
      "id": 541311,
      "name": "Дрибин",
      "lat": 54.118,
      "lng": 31.1,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 33011,
      "name": "Дрогичин",
      "lat": 52.2,
      "lng": 25.083,
      "timezone": 3,
      "icao": "Dr"
    },
    {
      "country": "BY",
      "id": 546307,
      "name": "Дубровно",
      "lat": 54.572,
      "lng": 30.69,
      "timezone": 3,
      "icao": "Dbn"
    },
    {
      "country": "BY",
      "id": 535254,
      "name": "Дятлово",
      "lat": 53.465,
      "lng": 25.403,
      "timezone": 3,
      "icao": "Dtl"
    },
    {
      "country": "BY",
      "id": 26566,
      "name": "Езерище",
      "lat": 55.833,
      "lng": 30,
      "timezone": 3,
      "icao": "Ez"
    },
    {
      "country": "BY",
      "id": 518292,
      "name": "Ельск",
      "lat": 51.806,
      "lng": 29.151,
      "timezone": 3,
      "icao": "Els"
    },
    {
      "country": "BY",
      "id": 522240,
      "name": "Жабинка",
      "lat": 52.197,
      "lng": 24.014,
      "timezone": 3,
      "icao": "Jab"
    },
    {
      "country": "BY",
      "id": 33027,
      "name": "Житковичи",
      "lat": 52.214,
      "lng": 27.869,
      "timezone": 3,
      "icao": "Ji"
    },
    {
      "country": "BY",
      "id": 26966,
      "name": "Жлобин",
      "lat": 52.894,
      "lng": 30.044,
      "timezone": 3,
      "icao": "Jl"
    },
    {
      "country": "BY",
      "id": 541283,
      "name": "Жодино",
      "lat": 54.096,
      "lng": 28.324,
      "timezone": 3,
      "icao": "Jdn"
    },
    {
      "country": "BY",
      "id": 540273,
      "name": "Заславль",
      "lat": 54.007,
      "lng": 27.293,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 532248,
      "name": "Зельва",
      "lat": 53.15,
      "lng": 24.817,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 521255,
      "name": "Иваново",
      "lat": 52.147,
      "lng": 25.536,
      "timezone": 3,
      "icao": "Ivv"
    },
    {
      "country": "BY",
      "id": 26938,
      "name": "Ивацевичи",
      "lat": 52.717,
      "lng": 25.35,
      "timezone": 3,
      "icao": "Iv"
    },
    {
      "country": "BY",
      "id": 26833,
      "name": "Ивье",
      "lat": 53.943,
      "lng": 25.753,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 521293,
      "name": "Калинковичи",
      "lat": 52.129,
      "lng": 29.333,
      "timezone": 3,
      "icao": "Kal"
    },
    {
      "country": "BY",
      "id": 524238,
      "name": "Каменец",
      "lat": 52.402,
      "lng": 23.822,
      "timezone": 3,
      "icao": "Kmn"
    },
    {
      "country": "BY",
      "id": 533295,
      "name": "Кировск",
      "lat": 53.274,
      "lng": 29.5,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 531266,
      "name": "Клецк",
      "lat": 53.067,
      "lng": 26.634,
      "timezone": 3,
      "icao": "Klz"
    },
    {
      "country": "BY",
      "id": 536320,
      "name": "Климовичи",
      "lat": 53.61,
      "lng": 31.963,
      "timezone": 3,
      "icao": "Kli"
    },
    {
      "country": "BY",
      "id": 26864,
      "name": "Кличев",
      "lat": 53.493,
      "lng": 29.312,
      "timezone": 3,
      "icao": "Kl"
    },
    {
      "country": "BY",
      "id": 33005,
      "name": "Кобрин",
      "lat": 52.196,
      "lng": 24.33,
      "timezone": 3,
      "icao": "Kob"
    },
    {
      "country": "BY",
      "id": 532271,
      "name": "Копыль",
      "lat": 53.152,
      "lng": 27.091,
      "timezone": 3,
      "icao": "Kpl"
    },
    {
      "country": "BY",
      "id": 536261,
      "name": "Кореличи",
      "lat": 53.57,
      "lng": 26.139,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 531308,
      "name": "Корма",
      "lat": 53.133,
      "lng": 30.797,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 26887,
      "name": "Костюковичи",
      "lat": 53.359,
      "lng": 32.071,
      "timezone": 3,
      "icao": "Ks"
    },
    {
      "country": "BY",
      "id": 533314,
      "name": "Краснополье",
      "lat": 53.336,
      "lng": 31.4,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 537317,
      "name": "Кричев",
      "lat": 53.696,
      "lng": 31.707,
      "timezone": 3,
      "icao": "Kri"
    },
    {
      "country": "BY",
      "id": 542298,
      "name": "Круглое",
      "lat": 54.247,
      "lng": 29.799,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 543291,
      "name": "Крупки",
      "lat": 54.32,
      "lng": 29.143,
      "timezone": 3,
      "icao": "Kru"
    },
    {
      "country": "BY",
      "id": 33105,
      "name": "Лельчицы",
      "lat": 51.802,
      "lng": 28.328,
      "timezone": 3,
      "icao": "Le"
    },
    {
      "country": "BY",
      "id": 26659,
      "name": "Лепель",
      "lat": 54.88,
      "lng": 28.705,
      "timezone": 3,
      "icao": "Ll"
    },
    {
      "country": "BY",
      "id": 26832,
      "name": "Лида",
      "lat": 53.902,
      "lng": 25.323,
      "timezone": 3,
      "icao": "Li"
    },
    {
      "country": "BY",
      "id": 550308,
      "name": "Лиозно",
      "lat": 55.027,
      "lng": 30.795,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 542278,
      "name": "Логойск",
      "lat": 54.2,
      "lng": 27.848,
      "timezone": 3,
      "icao": "Log"
    },
    {
      "country": "BY",
      "id": 519308,
      "name": "Лоев",
      "lat": 51.939,
      "lng": 30.806,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 522268,
      "name": "Лунинец",
      "lat": 52.246,
      "lng": 26.798,
      "timezone": 3,
      "icao": "Lun"
    },
    {
      "country": "BY",
      "id": 26645,
      "name": "Лынтупы",
      "lat": 55.048,
      "lng": 26.307,
      "timezone": 3,
      "icao": "Ly"
    },
    {
      "country": "BY",
      "id": 528280,
      "name": "Любань",
      "lat": 52.792,
      "lng": 28.001,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 530263,
      "name": "Ляховичи",
      "lat": 53.038,
      "lng": 26.267,
      "timezone": 3,
      "icao": "Ljx"
    },
    {
      "country": "BY",
      "id": 33061,
      "name": "Малорита",
      "lat": 51.812,
      "lng": 24.078,
      "timezone": 3,
      "icao": "Mlt"
    },
    {
      "country": "BY",
      "id": 26855,
      "name": "Марьина Горка",
      "lat": 53.492,
      "lng": 28.141,
      "timezone": 3,
      "icao": "Mg"
    },
    {
      "country": "BY",
      "id": 522275,
      "name": "Микашевичи",
      "lat": 52.218,
      "lng": 27.471,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 539280,
      "name": "Минск-аэропорт",
      "lat": 53.883,
      "lng": 28.031,
      "timezone": 3,
      "icao": "UMMS"
    },
    {
      "country": "BY",
      "id": 26857,
      "name": "Минск-Бобруйская",
      "lat": 53.889,
      "lng": 27.558,
      "timezone": 3,
      "icao": "UMMS"
    },
    {
      "country": "BY",
      "id": 26856,
      "name": "Минск-Богдановича",
      "lat": 53.949,
      "lng": 27.597,
      "timezone": 3,
      "icao": "UMMS"
    },
    {
      "country": "BY",
      "id": 26854,
      "name": "Минск-Корженевского",
      "lat": 53.843,
      "lng": 27.52,
      "timezone": 3,
      "icao": "UMMS"
    },
    {
      "country": "BY",
      "id": 26851,
      "name": "Минск-Независимости",
      "lat": 53.93,
      "lng": 27.64,
      "timezone": 3,
      "icao": "UMMS"
    },
    {
      "country": "BY",
      "id": 26858,
      "name": "Минск-Радиальная",
      "lat": 53.896,
      "lng": 27.644,
      "timezone": 3,
      "icao": "UMMS"
    },
    {
      "country": "BY",
      "id": 26852,
      "name": "Минск-Тимирязева",
      "lat": 53.913,
      "lng": 27.523,
      "timezone": 3,
      "icao": "UMMS"
    },
    {
      "country": "BY",
      "id": 26850,
      "name": "Минск-Уручье",
      "lat": 53.96,
      "lng": 27.7,
      "timezone": 3,
      "icao": "UMMS"
    },
    {
      "country": "BY",
      "id": 26842,
      "name": "Минск-Шаранговича",
      "lat": 53.884,
      "lng": 27.442,
      "timezone": 3,
      "icao": "UMMS"
    },
    {
      "country": "BY",
      "id": 556276,
      "name": "Миоры",
      "lat": 55.622,
      "lng": 27.617,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 26862,
      "name": "Могилев",
      "lat": 53.871,
      "lng": 30.367,
      "timezone": 3,
      "icao": "UMOO"
    },
    {
      "country": "BY",
      "id": 540301,
      "name": "Могилев-аэропорт",
      "lat": 53.956,
      "lng": 30.096,
      "timezone": 3,
      "icao": "UMOO"
    },
    {
      "country": "BY",
      "id": 33036,
      "name": "Мозырь",
      "lat": 52.036,
      "lng": 29.192,
      "timezone": 3,
      "icao": "Mz"
    },
    {
      "country": "BY",
      "id": 543268,
      "name": "Молодечно",
      "lat": 54.31,
      "lng": 26.842,
      "timezone": 3,
      "icao": "Mol"
    },
    {
      "country": "BY",
      "id": 534245,
      "name": "Мосты",
      "lat": 53.414,
      "lng": 24.544,
      "timezone": 3,
      "icao": "Mos"
    },
    {
      "country": "BY",
      "id": 26779,
      "name": "Мстиславль",
      "lat": 54.02,
      "lng": 31.742,
      "timezone": 3,
      "icao": "Mt"
    },
    {
      "country": "BY",
      "id": 549269,
      "name": "Мядель",
      "lat": 54.871,
      "lng": 26.929,
      "timezone": 3,
      "icao": "Mjd"
    },
    {
      "country": "BY",
      "id": 518295,
      "name": "Наровля",
      "lat": 51.796,
      "lng": 29.507,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 26649,
      "name": "Нарочь",
      "lat": 54.9,
      "lng": 26.683,
      "timezone": 3,
      "icao": "Na"
    },
    {
      "country": "BY",
      "id": 532267,
      "name": "Несвиж",
      "lat": 53.219,
      "lng": 26.688,
      "timezone": 3,
      "icao": "Nes"
    },
    {
      "country": "BY",
      "id": 26836,
      "name": "Новогрудок",
      "lat": 53.6,
      "lng": 25.85,
      "timezone": 3,
      "icao": "Ng"
    },
    {
      "country": "BY",
      "id": 547291,
      "name": "Новолукомль",
      "lat": 54.655,
      "lng": 29.143,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 555286,
      "name": "Новополоцк",
      "lat": 55.535,
      "lng": 28.645,
      "timezone": 3,
      "icao": "Nvp"
    },
    {
      "country": "BY",
      "id": 26838,
      "name": "Новоселки",
      "lat": 53.261,
      "lng": 25.306,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 26950,
      "name": "Октябрьский",
      "lat": 52.629,
      "lng": 28.873,
      "timezone": 3,
      "icao": "Ok"
    },
    {
      "country": "BY",
      "id": 26763,
      "name": "Орша",
      "lat": 54.503,
      "lng": 30.444,
      "timezone": 3,
      "icao": "UMIO"
    },
    {
      "country": "BY",
      "id": 544303,
      "name": "Орша-аэропорт",
      "lat": 54.44,
      "lng": 30.296,
      "timezone": 3,
      "icao": "UMIO"
    },
    {
      "country": "BY",
      "id": 533286,
      "name": "Осиповичи",
      "lat": 53.309,
      "lng": 28.639,
      "timezone": 3,
      "icao": "Osi"
    },
    {
      "country": "BY",
      "id": 546260,
      "name": "Островец",
      "lat": 54.616,
      "lng": 25.96,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 26736,
      "name": "Ошмяны",
      "lat": 54.433,
      "lng": 25.933,
      "timezone": 3,
      "icao": "Om"
    },
    {
      "country": "BY",
      "id": 521285,
      "name": "Петриков",
      "lat": 52.127,
      "lng": 28.496,
      "timezone": 3,
      "icao": "Ptr"
    },
    {
      "country": "BY",
      "id": 33019,
      "name": "Пинск",
      "lat": 52.122,
      "lng": 26.112,
      "timezone": 3,
      "icao": "Pi"
    },
    {
      "country": "BY",
      "id": 26754,
      "name": "Плещеницы",
      "lat": 54.434,
      "lng": 27.834,
      "timezone": 3,
      "icao": "Ple"
    },
    {
      "country": "BY",
      "id": 33015,
      "name": "Полесский",
      "lat": 52.3,
      "lng": 26.667,
      "timezone": 3,
      "icao": "Ps"
    },
    {
      "country": "BY",
      "id": 26653,
      "name": "Полоцк",
      "lat": 55.474,
      "lng": 28.752,
      "timezone": 3,
      "icao": "Po"
    },
    {
      "country": "BY",
      "id": 551268,
      "name": "Поставы",
      "lat": 55.113,
      "lng": 26.84,
      "timezone": 3,
      "icao": "Pov"
    },
    {
      "country": "BY",
      "id": 26929,
      "name": "Пружаны",
      "lat": 52.567,
      "lng": 24.483,
      "timezone": 3,
      "icao": "Pr"
    },
    {
      "country": "BY",
      "id": 542272,
      "name": "Радошковичи",
      "lat": 54.157,
      "lng": 27.224,
      "timezone": 3,
      "icao": "Rad"
    },
    {
      "country": "BY",
      "id": 524304,
      "name": "Речица",
      "lat": 52.372,
      "lng": 30.385,
      "timezone": 3,
      "icao": "Rec"
    },
    {
      "country": "BY",
      "id": 531301,
      "name": "Рогачев",
      "lat": 53.082,
      "lng": 30.054,
      "timezone": 3,
      "icao": "Rog"
    },
    {
      "country": "BY",
      "id": 26556,
      "name": "Россоны",
      "lat": 55.91,
      "lng": 28.794,
      "timezone": 3,
      "icao": "Rsn"
    },
    {
      "country": "BY",
      "id": 26843,
      "name": "Самохваловичи",
      "lat": 53.736,
      "lng": 27.513,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 526298,
      "name": "Светлогорск",
      "lat": 52.633,
      "lng": 29.751,
      "timezone": 3,
      "icao": "Sve"
    },
    {
      "country": "BY",
      "id": 530241,
      "name": "Свислочь",
      "lat": 53.037,
      "lng": 24.099,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 26668,
      "name": "Сенно",
      "lat": 54.804,
      "lng": 29.688,
      "timezone": 3,
      "icao": "Se"
    },
    {
      "country": "BY",
      "id": 536242,
      "name": "Скидель",
      "lat": 53.59,
      "lng": 24.243,
      "timezone": 3,
      "icao": "Skl"
    },
    {
      "country": "BY",
      "id": 26878,
      "name": "Славгород",
      "lat": 53.451,
      "lng": 31.004,
      "timezone": 3,
      "icao": "Sa"
    },
    {
      "country": "BY",
      "id": 531253,
      "name": "Слоним",
      "lat": 53.089,
      "lng": 25.321,
      "timezone": 3,
      "icao": "Slo"
    },
    {
      "country": "BY",
      "id": 26951,
      "name": "Слуцк",
      "lat": 53.053,
      "lng": 27.552,
      "timezone": 3,
      "icao": "Sl"
    },
    {
      "country": "BY",
      "id": 538280,
      "name": "Смиловичи",
      "lat": 53.75,
      "lng": 28.02,
      "timezone": 3,
      "icao": "Sml"
    },
    {
      "country": "BY",
      "id": 540281,
      "name": "Смолевичи",
      "lat": 54.031,
      "lng": 28.09,
      "timezone": 3,
      "icao": "Smv"
    },
    {
      "country": "BY",
      "id": 545264,
      "name": "Сморгонь",
      "lat": 54.484,
      "lng": 26.403,
      "timezone": 3,
      "icao": "Smo"
    },
    {
      "country": "BY",
      "id": 26957,
      "name": "Солигорск",
      "lat": 52.775,
      "lng": 27.552,
      "timezone": 3,
      "icao": "Sol"
    },
    {
      "country": "BY",
      "id": 530283,
      "name": "Старые Дороги",
      "lat": 53.04,
      "lng": 28.266,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 26846,
      "name": "Столбцы",
      "lat": 53.466,
      "lng": 26.733,
      "timezone": 3,
      "icao": "St"
    },
    {
      "country": "BY",
      "id": 519269,
      "name": "Столин",
      "lat": 51.89,
      "lng": 26.851,
      "timezone": 3,
      "icao": "Stl"
    },
    {
      "country": "BY",
      "id": 544297,
      "name": "Толочин",
      "lat": 54.409,
      "lng": 29.693,
      "timezone": 3,
      "icao": "Tol"
    },
    {
      "country": "BY",
      "id": 521278,
      "name": "Туров",
      "lat": 52.065,
      "lng": 27.751,
      "timezone": 3,
      "icao": "Tur"
    },
    {
      "country": "BY",
      "id": 535272,
      "name": "Узда",
      "lat": 53.465,
      "lng": 27.215,
      "timezone": 3,
      "icao": "Uzd"
    },
    {
      "country": "BY",
      "id": 552286,
      "name": "Ушачи",
      "lat": 55.18,
      "lng": 28.616,
      "timezone": 3,
      "icao": "Ush"
    },
    {
      "country": "BY",
      "id": 519300,
      "name": "Хойники",
      "lat": 51.887,
      "lng": 29.967,
      "timezone": 3,
      "icao": "Ho"
    },
    {
      "country": "BY",
      "id": 534326,
      "name": "Хотимск",
      "lat": 53.408,
      "lng": 32.583,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 538310,
      "name": "Чаусы",
      "lat": 53.808,
      "lng": 30.974,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 549292,
      "name": "Чашники",
      "lat": 54.86,
      "lng": 29.16,
      "timezone": 3,
      "icao": "Chk"
    },
    {
      "country": "BY",
      "id": 537284,
      "name": "Червень",
      "lat": 53.714,
      "lng": 28.419,
      "timezone": 3,
      "icao": "Сhr"
    },
    {
      "country": "BY",
      "id": 536314,
      "name": "Чериков",
      "lat": 53.572,
      "lng": 31.389,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 529309,
      "name": "Чечерск",
      "lat": 52.92,
      "lng": 30.917,
      "timezone": 3,
      "icao": "Ch"
    },
    {
      "country": "BY",
      "id": 26643,
      "name": "Шарковщина",
      "lat": 55.363,
      "lng": 27.457,
      "timezone": 3,
      "icao": "Sh"
    },
    {
      "country": "BY",
      "id": 534277,
      "name": "Шацк",
      "lat": 53.435,
      "lng": 27.695,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 542303,
      "name": "Шклов",
      "lat": 54.203,
      "lng": 30.295,
      "timezone": 3,
      "icao": "Shk"
    },
    {
      "country": "BY",
      "id": 553296,
      "name": "Шумилино",
      "lat": 55.299,
      "lng": 29.617,
      "timezone": 3,
      "icao": ""
    },
    {
      "country": "BY",
      "id": 26834,
      "name": "Щучин",
      "lat": 53.583,
      "lng": 24.75,
      "timezone": 3,
      "icao": "Hu"
    }
  ]