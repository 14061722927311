import React, { ChangeEvent, FC } from "react";
interface ICurrencuInput {
  amount: string,
  onAmountChange: (e:ChangeEvent<HTMLInputElement>) => void;
  name: string
}

export const CurrencyInput: FC<ICurrencuInput> = ({amount, onAmountChange, name}) => {
  return (
    <input 
      type="text" 
      className="bg-white text-3xl border-0 bg-white mt-3 w-full text-gray-700 outline-0" 
      onChange={onAmountChange}  
      value={amount}
      name={name}
    />
  );
}