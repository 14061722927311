import React, { Dispatch, FC, SetStateAction } from "react";
import cities from './data/cities_by.json';

interface ICityPopup {
  open: boolean;
  onCityChange: (id:number) => void;
  onClose: Dispatch<SetStateAction<boolean>>
}

export const CityPopup: FC<ICityPopup> = ({open, onCityChange, onClose}) => {
  return (
    <span className={`w-full flex-col absolute z-10 bg-white text-sm p-4 pt-0 left-0 rounded-b-xl shadow-lg shadow-gray-900 max-h-full overflow-auto ${open ? 'flex' : 'hidden'}`}>
      <h4 className="flex justify-between items-center font-bold text-lg mb-2">
        Выберите город
        <span className="material-symbols-rounded cursor-pointer hover:text-gray-600" onClick={() => onClose(false)}>close</span>
      </h4>
      <ul className="columns-2">
        {
          cities.map(c => {
            return (
              <li>
                <a href="#" onClick={() => onCityChange(c.id)}>{c.name}</a>
              </li>
            );
          })
        }
      </ul>
    </span>
  );
}