import React, { FC } from 'react';
import { Currency } from '../../../types';

interface ICurrencyRate {
  currency: Currency
}

export const CurrencyRate: FC<ICurrencyRate> = ({currency}) => {
  return (
    <div className='text-xs uppercase text-gray-500 mt-3'>
      <span>{currency.Cur_Scale} {currency.Cur_Abbreviation}</span>
      <span className='mx-2'>=</span>
      <span>
        {
          currency.Cur_Abbreviation === 'BYN' ? (1/currency.Cur_OfficialRate).toFixed(2) : currency.Cur_OfficialRate
        } {
          currency.Cur_Abbreviation === 'BYN' ? currency.Cur_Abbreviation : 'BYN'
        }
        </span>
    </div>
  );
}