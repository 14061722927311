import React, { FC, useState, useRef, useCallback, useEffect, MouseEventHandler } from "react";
import { Currency } from "../../../types";
import countryCurrency from "../data/countryCurrency.json";

interface ICurrencyDropdown {
  preSelected: Currency,
  currencies: Currency[] | undefined,
  onCurrencyChange: (from: string, to:string) => void;
}

export const CurrencyDropdown: FC<ICurrencyDropdown> = ({ preSelected, currencies, onCurrencyChange }) => {
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(preSelected);
  const [opened, setOpened] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const ddRef = useRef<HTMLDivElement>(null);

  const closeDropdown = useCallback((e) => {
    if (ref.current && opened && !ref.current.contains(e.target)) {
      console.log("callback");
      if(ddRef.current && !ddRef.current.contains(e.target)) {
        setOpened(false);
      }      
    }
  }, [opened]);

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown);
    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    }
  }, [closeDropdown]);

  const handleCurrencyClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const data = e.currentTarget.dataset;
    onCurrencyChange(data.from!, data.to!);
    setOpened(false);
  }

  return (
    selectedCurrency &&
    <section className="relative">
      <div className="flex items-center cursor-pointer text-sm" onClick={() => setOpened(!opened)} ref={ddRef}>
        <img className="mr-2 rounded-full h-6 w-6" src={`https://anw.by/images/flags/${countryCurrency[selectedCurrency.Cur_Abbreviation].country}.svg`} alt={selectedCurrency.Cur_Name} />
        <span className="flex-1"><span>{selectedCurrency.Cur_Abbreviation}</span> - <span>{countryCurrency[selectedCurrency.Cur_Abbreviation].name}</span></span>
        <span className="w-0 h-0 border-x-6 border-l-transparent border-r-transparent border-t-6 border-t-gray-600"></span>
      </div>
      <div 
        className={`flex flex-col absolute top-full mt-2 -left-4 -right-4 shadow-lg rounded-lg overflow-auto bg-white z-10 max-h-[40vh] ${opened ? 'block' : 'hidden'}`}
        ref={ref}
      >
        {
          currencies && currencies.map(c => {
            return (
              preSelected.Cur_Abbreviation !== c.Cur_Abbreviation &&
              <div 
                className={`flex items-center text-sm px-4 py-2 siblings:border-t cursor-pointer hover:bg-gray-100`}
                onClick={handleCurrencyClick}
                data-from={selectedCurrency.Cur_Abbreviation}
                data-to={c.Cur_Abbreviation}
                key={`dd-${c.Cur_ID}`}
              >
                <img className="mr-2 rounded-full h-6 w-6" src={`https://anw.by/images/flags/${countryCurrency[c.Cur_Abbreviation]?.country}.svg`} alt={c.Cur_Name} />
                <span className="flex-1"><span>{c.Cur_Abbreviation}</span> - <span>{countryCurrency[c.Cur_Abbreviation]?.name}</span></span>
              </div>
            )
          })
        }
      </div>
    </section>
  );
}