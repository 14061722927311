import React, { FC, useState, useEffect, ChangeEvent, ReactEventHandler, MouseEventHandler } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Currency } from '../../types';
import { CurrencyDropdown, CurrencyInput, CurrencyRate } from './components';
let { CURRENCY_API_URL } = process.env;

const DEFAULT_PAIRS = ["BYN", "USD", "RUB"];

export const Converter: FC = () => {
  const [currencies, setCurrencies] = useState<Currency[]>();
  const [availableCurrencies, setAvailableCurrencies] = useState<Currency[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get<Currency[]>(`${CURRENCY_API_URL}`)
      .then((response) => {
        if(response && response.data.length > 0) {
          response.data.unshift({
            Cur_Abbreviation: "BYN",
            Cur_ID: 0,
            Cur_Name: "Белорусский рубль",
            Cur_OfficialRate: 1,
            Cur_Scale: 1,
            Date: response.data[0].Date,
            Amount: "10"
          });

          setAvailableCurrencies(response.data);
          setCurrencyGoups(response.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }, []);

  const setCurrencyGoups = (curr: Currency[]) => {
    const currencyGroups: Currency[] = [];
    DEFAULT_PAIRS.forEach(p => {
      const group = curr.find(c => c.Cur_Abbreviation === p);
      if(group) {
        group.Amount = (10 / group.Cur_OfficialRate * group.Cur_Scale).toFixed(2);
        currencyGroups.push(group);
      };      
    });

    setCurrencies(currencyGroups);
  }

  const handleAmountChange = (e:ChangeEvent<HTMLInputElement>) => {
    changeGroupsAmount(e.target.name, e.target.value);
  }

  const handleCurrencyChange = (from: string, to:string) => {
    changeGroupsAmount(from, undefined, to);
  }

  const changeGroupsAmount = (name: string, value?:string, to?: string) => {
    const currentGroups = currencies?.slice();
    let currentCurrency = currentGroups?.find(c => c.Cur_Abbreviation === name);
    let amount = value;

    if(to && currentGroups && availableCurrencies && availableCurrencies?.length > 0) {
      const fromIndex = currentGroups?.findIndex(g => g.Cur_Abbreviation === name);
      if(!amount) {
        amount = currentGroups[fromIndex].Amount
      }
      currentGroups[fromIndex] = availableCurrencies?.find(c => c.Cur_Abbreviation === to) || availableCurrencies[0];  
      currentCurrency = currentGroups[fromIndex];
    }

    if(currentCurrency) {
      const multiplier = Number(amount) * currentCurrency?.Cur_OfficialRate / currentCurrency?.Cur_Scale;

      currentGroups?.forEach(g => {
        if(g.Cur_Abbreviation !== (to || name)) {
          g.Amount = (multiplier / g.Cur_OfficialRate * g.Cur_Scale).toFixed(2);
        } else {
          g.Amount = amount;
        }
      });

      setCurrencies(currentGroups);
    }
  }

  return (
    <div>
      <div className="rounded-xl p-6 shadow-lg flex flex-col bg-white w-full sm:w-88">
        <h3>Конвертер валют</h3>
        <div className="mt-4 bg-white rounded-xl shadow-rounded-xl p-4">
          <div className="divide">
            {
              currencies?.length && currencies.map(c => {
                return (
                  <div key={c.Cur_ID} className='mb-4 siblings:pt-6 siblings:border-t'>
                    <CurrencyDropdown preSelected={c} currencies={availableCurrencies} onCurrencyChange={handleCurrencyChange} />  
                    <CurrencyRate currency={c} />
                    <CurrencyInput amount={c?.Amount || ""} onAmountChange={handleAmountChange} name={c.Cur_Abbreviation} />
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className="mt-4 flex flex-col text-xs text-gray-400">
          <span>По состоянию на <strong>{availableCurrencies && dayjs(availableCurrencies[0].Date).format('DD.MM.YYYY')}</strong></span>
          <span>Источник <a href="https://nbrb.by" target={"_blank"}>Национальный Банк Республики Беларусь</a></span>
        </div>
      </div>
    </div>
  );
}