import React, { FC } from "react";
import { Converter } from "./areas/converter";
import { Weather } from "./areas/weather/Weather";

export const App: FC = () => {
  return (
    <section className="flex flex-wrap justify-center flex-col-reverse sm:flex-row w-full sm:space-x-8">
      {/* CONVERTER */}
      <Converter />
      {/* WEATHER */}
      <Weather />
    </section>
  );
}