import React, { FC, useState } from "react";
import { CurrentWeather, DailyWeatherFormatted } from "../../types";

interface ICurrent {
  current: CurrentWeather,
  today: DailyWeatherFormatted
}

export const Current: FC<ICurrent> = ({ current, today }) => {
  const now = new Date();
  const currentHour = [0, 6, 12, 18].reduce((result, current) => {
    return Math.abs(current - now.getHours()) < Math.abs(result - now.getHours()) ? current : result;
  });

  if(current.status) {
    current.t = today.daytime[currentHour].TMP;
    current.speedWind = today.daytime[currentHour].WINDSP_MAX;
    current.pSea = today.daytime[currentHour].PRESSURE_MAX;
    current.w = today.daytime[currentHour].RH_MAX;

    console.log(currentHour);
  }

  return (
    <section className="flex flex-col text-white">
      <div className="flex items-center">
        <div><img className="max-h-12" src={`https://anw.by/images/weather/${today.daytime[currentHour].ww}.svg`} alt="" /></div>
        <div className="flex flex-col ml-3">
          {/* TEMPERATURE */}
          <div className="text-5xl flex items-center">
            <span className="text-sm">{current.t > 0 ? '+' : ''}</span>
            {current.t}&deg;
          </div>
        </div>
      </div>
      {/* ADDITIONAL INFO */}
      <div className="flex items-center justify-between text-sm mt-3">
        {/* HUMIDITY */}
        <div className="flex items-center">
          <span className="material-symbols-rounded text-sm mr-1">air</span>
          <span>{current.speedWind}м/с</span>
          <span className="material-symbols-rounded text-xs ml-1" style={{transform: `rotate(${current.dirWind + 180}deg)`}}>navigation</span>
        </div>
        {/* WIND */}
        <div className="flex items-center">
          <span className="material-symbols-rounded text-sm mr-1">humidity_high</span>
          <span>{current.w}%</span>
        </div>
        {/* PRESSURE */}
        <div className="flex items-center">
          <span className="material-symbols-rounded text-sm mr-1">avg_pace</span>
          <span>{(current.pSea / 1.333).toFixed()}мм рт.ст.</span>
        </div>
      </div>
    </section>
  );
}