import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { CurrentWeather, DailyWeatherFormatted, WeatherResponse, City } from '../../types';
import { CityPopup, Current, Week } from './';
let { WEATHER_API_URL } = process.env;
import cities from './data/cities_by.json';
const DEFAULT_CITY:City = {
  "country": "BY",
  "id": 26966,
  "name": "Жлобин",
  "lat": 52.894,
  "lng": 30.044,
  "timezone": 3,
  "icao": "Jl"
}

export const Weather: FC = () => {
  const [forecast, setForecast] = useState<DailyWeatherFormatted[]>();
  const [currentWeather, setCurrentWeather] = useState<CurrentWeather>();
  const [cityPopupOpen, setCityPopupOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState<City>(DEFAULT_CITY);

  useEffect(() => {
    if(!selectedCity) {
      return;
    }
    axios.get<WeatherResponse>(`${WEATHER_API_URL}?city=${selectedCity.id}`)
      .then(response => {
        if(response && response.data) {          
          const day = Object.keys(response.data.daily)[0];
          const date = dayjs(day);
          const dailyDirty = Object.values(response.data.daily[day]);
          let weekIndex = 0;
          let dayTimeIndex = 0;
          const dailyWeather: DailyWeatherFormatted[] = [];

          dailyDirty.forEach((d, index) => {
            if(d.ADVANCE_TIME % 24 === 0) {
              weekIndex = d.ADVANCE_TIME / 24;
              dayTimeIndex = 0;

              const weekDay = {
                date: date.add(d.ADVANCE_TIME, 'h').format(),
                daytime: {[dayTimeIndex]: d}
              }

              dailyWeather.push(weekDay)
            } else {
              if(!dailyWeather[weekIndex]) {
                dayTimeIndex = d.ADVANCE_TIME;
                const weekDay = {
                  date: date.format(),
                  daytime: {[dayTimeIndex]: d}
                }
                dailyWeather.push(weekDay)
                return;
              }
              dayTimeIndex += 6;
              dailyWeather[weekIndex].daytime[dayTimeIndex] = d;
            }
          });
          
          setCurrentWeather(response.data.current);
          setForecast(dailyWeather);
        }
      })
      .catch(error => {
        console.log(error)
      });
  }, [selectedCity]);

  const handleCityChange = (id: number) => {
    const city = cities.find(c => c.id === id);
    if(city) {
      setSelectedCity(city);
      setCityPopupOpen(false);
    }
  }

  return (
    <div>
      <div className="rounded-xl p-6 shadow-lg flex flex-col bg-white w-full sm:w-88 relative">
        <h3 className='flex justify-between flex-wrap items-center'>
          <span>Погода</span>          
          <span className='flex items-center'>
            <span className="material-symbols-rounded text-base mr-1 text-gray-500">location_on</span>
            <a href="#" className='text-gray-500 text-base flex items-center' onClick={() => setCityPopupOpen(!cityPopupOpen)}>
              {selectedCity.name}
            </a>
          </span>          
        </h3>
        <CityPopup open={cityPopupOpen} onCityChange={handleCityChange} onClose={setCityPopupOpen} />
        <div className="mt-4 rounded-xl shadow-rounded-xl p-4 bg-blue-400 ">
          {currentWeather && forecast && <Current current={currentWeather} today={forecast.find(f => f.daytime[0]) || forecast[0]}  />}
        </div>
        <div className='mt-4 rounded-xl shadow-rounded-xl p-4 bg-blue-100'>
          {forecast && <Week week={forecast} />}
        </div>
        <div className="mt-4 flex flex-col text-xs text-gray-400">
          <span>Обновлено в <strong>{currentWeather && dayjs(currentWeather.d).format('HH:mm')}</strong></span>
          <span>По информации <a href="https://pogoda.by/" target={"_blank"}>БЕЛГИДРОМЕТ</a></span>
        </div>
      </div>
    </div>
  );
}