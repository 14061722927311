{
    "AUD": {
      "name": "Австралийский доллар",
      "country": "au"
    },
    "BGN": {
      "name": "Болгарский лев",
      "country": "bg"
    },
    "UAH": {
      "name": "Гривна",
      "country": "ua"
    },
    "DKK": {
      "name": "Датская крона",
      "country": "dk"
    },
    "USD": {
      "name": "Доллар США",
      "country": "us"
    },
    "EUR": {
      "name": "Евро",
      "country": "eu"
    },
    "PLN": {
      "name": "Злотый",
      "country": "pl"
    },
    "IRR": {
      "name": "Иранский риал",
      "country": "ir"
    },
    "ISK": {
      "name": "Исландская крона",
      "country": "is"
    },
    "JPY": {
      "name": "Йена",
      "country": "jp"
    },
    "CAD": {
      "name": "Канадский доллар",
      "country": "ca"
    },
    "CNY": {
      "name": "Китайский юань",
      "country": "cn"
    },
    "KWD": {
      "name": "Кувейтский динар",
      "country": "kw"
    },
    "MDL": {
      "name": "Молдавский лей",
      "country": "md"
    },
    "NZD": {
      "name": "Новозеландский доллар",
      "country": "nz"
    },
    "NOK": {
      "name": "Норвежская крона",
      "country": "no"
    },
    "RUB": {
      "name": "Российский рубль",
      "country": "ru"
    },
    "BYN": {
      "name": "Белорусский рубль",
      "country": "by"
    },
    "SGD": {
      "name": "Сингапурcкий доллар",
      "country": "sg"
    },
    "KGS": {
      "name": "Сом",
      "country": "kg"
    },
    "KZT": {
      "name": "Тенге",
      "country": "kz"
    },
    "TRY": {
      "name": "Турецкая лира",
      "country": "tr"
    },
    "GBP": {
      "name": "Фунт стерлингов",
      "country": "gb"
    },
    "CZK": {
      "name": "Чешская крона",
      "country": "cz"
    },
    "SEK": {
      "name": "Шведская крона",
      "country": "se"
    },
    "CHF": {
      "name": "Швейцарский франк",
      "country": "ch"
    }
  }